<template>
  <div class="person-profile-reviews-item">
    <div class="header">
      <div class="left">
        <base-avatar :user="review.author"></base-avatar>
        <div class="name-and-date">
          <div class="name">{{ review.author.fullName }}</div>
          <div class="date">
            {{ review.createdAt | date }}
          </div>
        </div>
      </div>
      <div class="right">
        <star-rating class="star-rating"
                     :rating="review.rating"
                     :star-size="25"
                     :show-rating="false"
                     :read-only="true"
                     :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                     :rounded-corners="false"></star-rating>
      </div>
    </div>
    <div class="comment">
      {{ review.comment || $t('persons.personProfileReviewsItem.noComment') }}
    </div>
  </div>
</template>

<script>
  import StarRating from 'vue-star-rating'

  export default {
    props: {
      review: {
        required: true,
        type: Object
      }
    },

    components: {
      StarRating
    }
  }
</script>

<style lang="scss">
  .person-profile-reviews-item {
    border: 1px solid #eee;
    padding: 20px;
    margin-bottom: 20px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .left {
        display: flex;
        align-items: center;

        .name-and-date {
          margin-left: 10px;

          .date {
            color: #797979;
            font-size: 12px;
          }
        }
      }

      .right {
        margin-right: 10px;
      }
    }

    .comment {
      color: #797979;
    }
  }
</style>