<template>
  <div class="person-profile-reviews-form">
    <div class="heading">
      {{ $t('persons.personProfileReviews.title') }}
    </div>
    <star-rating class="star-rating"
                 v-model="rating"
                 :star-size="25"
                 :show-rating="false"
                 :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                 :rounded-corners="false"></star-rating>
    <div class="empty-rating-error" v-show="showEmptyRatingError">
      {{ $t('persons.personProfileReviews.ratingError') }}
    </div>
    <base-text-box :label="$t('persons.personProfileReviews.commentLabel')"
                   v-model="review"
                   :multi-line="true"
                   :rows="10"></base-text-box>

    <base-button @click="submit" :loading="loading">
      {{ $t('persons.personProfileReviews.send') }}
    </base-button>
  </div>
</template>

<script>
  import StarRating from 'vue-star-rating'
  import profileService from '@/services/queries/profileQueries.js'

  export default {
    props: {
      person: {
        required: true,
        type: Object
      }
    },

    components: {
      StarRating
    },

    data() {
      return {
        rating: 0,
        review: '',
        loading: false,
        showEmptyRatingError: false
      }
    },

    methods: {
      submit() {
        if (this.rating === 0) {
          this.showEmptyRatingError = true
          return
        }

        this.loading = true

        profileService.storeReview(this.person.id, this.rating, this.review).then(() => {
          window.location.reload()
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style lang="scss">


  .person-profile-reviews-form {
    .heading {
      color: $primary-color;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .star-rating {
      margin-bottom: 10px;
    }

    .empty-rating-error {
      color: #FF7474;
      margin-bottom: 10px;
    }
  }
</style>
