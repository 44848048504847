<template>
  <div class="person-profile-reviews">
    <person-profile-reviews-form class="review-form"
                                 :person="person"
                                 v-if="showAddReviewForm">
    </person-profile-reviews-form>

    <div class="review-list">
      <person-profile-reviews-item v-for="(review, key) in reviews"
                                  :review="review"
                                  :key="key">
      </person-profile-reviews-item>
    </div>
  </div>
</template>

<script>
  import profileService from '@/services/queries/profileQueries.js'
  import PersonProfileReviewsItem from './PersonProfileReviewsItem.vue'
  import PersonProfileReviewsForm from './PersonProfileReviewsForm.vue'

  export default {
    props: {
      person: {
        required: true,
        type: Object
      }
    },

    components: {
      PersonProfileReviewsForm,
      PersonProfileReviewsItem
    },

    data() {
      return {
        reviews: [],
        reviewAlreadyLeft: false
      }
    },

    created() {
      profileService.getReviews(this.person.id).then(data => this.reviews = data.items)
    },

    beforeRouteEnter(to, from, next) {
      profileService.reviewLeft(to.params.id).then(result => {
        next(vm => {
          vm.reviewAlreadyLeft = result
        })
      })
    },

    computed: {
      showAddReviewForm() {
        // TODO: use transformer for $currentUser, so that user_id became just id
        if (!this.$currentUser || (this.$currentUser.user_id === this.person.id) || this.reviewAlreadyLeft) {
          return false
        }

        return true
      }
    }
  }
</script>

<style lang="scss">


  .person-profile-reviews {
    .review-list {
      margin: 20px 0;
    }
  }
</style>
